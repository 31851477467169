* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: Arial, sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    margin: 0;
    background: #17171C;
}

.pdf-annotator {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    max-height: 100vh;
}

.toolbar {
    display: flex;
    flex-direction: column;
    padding: 5px;
    background-color: black;
    margin: 20px;
    border-radius: 50px;
    position: fixed;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1000;
}

.toolbar button {
    cursor: pointer;
    font-size: 16px;
    padding: 7px;
    border-radius: 50%;
    border: 0;
    margin: 5px 0;
    background: none;
    color: #fff;
    outline: none;
    transition: transform 0.2s ease, background-color 0.2s ease;
}

.toolbar button:hover {
    background-color: #2b2b2b;
    transform: scale(1.1);
}

.pdf-container {
    display: flex;
    justify-content: center;
    max-height: 90vh;
    flex-wrap: wrap;
}

.page {
    position: relative;
    margin: 0;
}

canvas {
    border: 1px solid #ccc;
    position: absolute;
}

.annotation-layer {
    position: absolute;
    top: 0;
    left: 0;
}

.back-button {
    position: fixed;
    top: 10px;
    left: 10px;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 24px;
    color: #fff;
    z-index: 1000;
    transition: transform 0.2s ease;
}

.back-button:hover {
    transform: scale(1.1);
}

.modal {
    display: none;
    position: fixed;
    z-index: 1001;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    color: #fff;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
    background-color: #000000;
    margin: 15% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    max-width: 300px;
    border-radius: 10px;
    text-align: center;
    position: relative;
}

.modal-buttons {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
    width: 100%;
}

.modal-button {
    padding: 10px 20px;
    border: 1px solid #fff;
    cursor: pointer;
    width: 45%;
    margin: 5px;
    border-radius: 25px;
    text-align: center;
}

.modal-button.save {
    background-color: #000;
    color: white;
}

.modal-button.cancel {
    background-color: #000;
    color: white;
}
