* {
  margin: 0;
  padding: 0;
  color: #fff;
  font-family: Arial, Helvetica, sans-serif;
  transition: .1s;
}
body {
  background: #17171C;
}
.top {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
}
.top .left h1 {
  width: 60%;
  margin-bottom: 10px;
}
.top .left select {
  background: #000;
  padding: 5px;
  border-radius: 10px;
  margin: 2.5px;
  outline: none;
  cursor: pointer;
}
.top .left select option {
  padding: 5px;
}
.top .right img {
  width: 300px;
}
.bottom .card {
  padding: 20px;
  justify-content: center;
  align-items: center;
  width: 200px;
  margin: 10px;
  background-color: green;
  background: linear-gradient(360deg, #35353E 0%, #17171C 100%);
  display: grid;
  border-radius: 5px;
}
.bottom .card:hover {
  transform: translateY(-5px);
}
.bottom {
  width: 80%;
  padding: 20px;
}
.bottom .book-list {
  width: 100%;
}
.bottom .book-list .list {
  display: flex !important;
  width: 100%;
}
.bookname {
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}
.thumbnail{
  width: 80%;
  border-radius: 5px;
  margin-bottom: 10px;
}